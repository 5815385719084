import.meta.glob([ '@images/**' ])
import "@styles/app.sass"
import "simple-lightbox/dist/simpleLightbox.css"
import SimpleLightbox from "simple-lightbox";
import Swiper from 'swiper';
import {Navigation, Pagination, Scrollbar} from 'swiper/modules';

(() => {
    if (!document.querySelector('.wp-block-gallery'))
        return

    document.querySelectorAll('.wp-block-gallery > figure > figcaption').forEach(el => {
        el.previousElementSibling.setAttribute('data-caption', el.textContent)
    })

    new SimpleLightbox({
        elements: '.wp-block-gallery figure > a',
        captionAttribute: 'data-caption'
    })
})();

(() => {
    if (!document.querySelector('[data-video]'))
        return

    new SimpleLightbox({
        elements: '.HomeAbout-video > a'
    })
})();

(() => {
    const words = document.querySelector(".HomeHero-rotatingText")?.children
    if (!words) return

    let index = 0

    const textInTimer = 3000,
        textOutTimer = 2800

    function animateText() {
        for(let i = 0; i < words.length; i++)
            words[i].classList.remove("going-in","going-out")
        words[index].classList.add("going-in")

        setTimeout(function(){
            words[index].classList.add("going-out")
        }, textOutTimer)

        setTimeout(function(){
            index = (index == words.length - 1) ? 0 : index + 1
            animateText();
        }, textInTimer);
    }

    animateText()
})();

document.addEventListener("DOMContentLoaded", () => {

    (() => { // Hiding header
        const header = document.querySelector('[data-page-header]') as HTMLElement
        if (!header) return

        const headerOffset = header.offsetTop - window.scrollY,
            headerHeight = (header.children[0] as HTMLElement)?.offsetHeight ?? 0

        let lastScroll = 0

        const hidingOnScroll = () => {
            const currentScroll = window.scrollY

            if (currentScroll > headerOffset + headerHeight) {
                header.classList.add('is-sticked')
                header.classList[currentScroll > lastScroll ? 'add' : 'remove']('is-up')
                document.body.classList[currentScroll > lastScroll ? 'remove' : 'add']('is-sticked-header')
            } else {
                header.classList.remove('is-sticked')
                header.classList.remove('is-up')
                document.body.classList.remove('is-sticked-header')
            }

            lastScroll = currentScroll
        }

        hidingOnScroll()
        window.addEventListener('scroll', () => hidingOnScroll(), { passive: true })

        const navOpener = document.querySelector('[data-nav-opener]')
        navOpener?.addEventListener("click", () => {
            document.body.classList.toggle('u-hiddenOverflow')
            navOpener.closest('[data-page-header]')?.classList.toggle('is-opened')
        })
    })();

    (() => {
        const slider = document.querySelector('[data-news-slides]') as HTMLElement
        if (!slider) return

        new Swiper(slider, {
            modules: [Navigation, Pagination, Scrollbar],
            slidesPerView: 1.75,
            spaceBetween: 64,
            pagination: {
                el: '[data-news-pagination]',
                type: 'fraction'
            },
            scrollbar: {
                el: "[data-news-scrollbar]"
            },
            navigation: {
                nextEl: "[data-news-next]",
                prevEl: "[data-news-prev]",
            },
            breakpoints: {
                320: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
                768: {
                    slidesPerView: 1.5,
                    spaceBetween: 40,
                },
                1200: {
                    slidesPerView: 1.75,
                    spaceBetween: 64,
                },
            }
        })
    })();
})